<template>
	<div class="page_wrap bg-f8">
		<top></top>
		<div class="home  fs30" id="target_top">
			<div class="header flex bg-ff align-center justify-center">
				<div class="headtit">
				</div>
			</div>
			<!-- 顶部end -->

			<!-- 主体内容 start -->
			<div class="  content ">
                <div v-for="(item,index) in lists" :key="index" class="bookitem bg-ff" @click="todetail(item.id)">
                    <div class="itemimg">
                        <img class="block-200" :src="item.thumb" alt="">
                    </div>
                    <div class="itemtit">
                        <img src="../../assets/image/fybq.png"  alt="">
                        <span class="itemname">{{item.name}}</span>
                    </div>
                    <div class="itemprice">
                        <div class="itemspprice">
                           <span class="fs-12 price">会员价￥</span>
                           <span class="price fs-20">{{item.member_price}}</span>
                           <span class="fs-12 price">/页</span>
                            <div class="itemmprice fs-12">
                            ￥{{item.market_price}}
                            </div>
                        </div>
                    </div>
                     <el-button class="ptbtn" type="danger">立即拼页</el-button>
                </div>
			</div>
			<!-- 主体内容 end -->
			<!-- 悬浮按钮 -->
			<div class="float-box flex flex-direction align-center justify-around" v-show="scrollTop>500">
				<img src="../../assets/image/home/top.png" class="block-28" @click="scrollToTop">
				<!-- <img src="../../../assets/image/home/add.png" class="block-19"> -->
				<i class="el-icon-circle-plus-outline fs28" :class="dialogTableVisible?'text-e54f':'text-333'" @click="dialogTableVisible=true"></i>
				<img src="../../assets/image/home/uptade_black.png" class="block-28 point" @click="update" :class="showRotate2 ? 'loading' : '' ">

			</div>
			<div class="mask" @click="dialogTableVisible=false" v-if="dialogTableVisible"></div>
			<div class="box-content bg-white radius-3  radius-10" v-if="dialogTableVisible">
				<div class="" style="position: relative;">
					<router-link to="/publish">
						<div class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point" >
							<img src="../../assets/image/home/add1.png" class="block-24">
							<span class="padding-left-10 fs14">动态</span>
						</div>
					</router-link>
					<div class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point" @click="toPublish(1,'/editArticle')">
						<img src="../../assets/image/home/add2.png" class="block-24">
						<span class="padding-left-10 fs14">文章（作家权限）</span>
					</div>
					<div class="flex align-center padding-tb-20 padding-lr-30 point" @click="toPublish(2,'/editBook')">
						<img src="../../assets/image/home/add3.png" class="block-24" >
						<span class="padding-left-10 fs14">电子书（作家权限）</span>
					</div>
					<div class="bg-f6f7 height10 w270">	</div>
				</div>
				<div class="padding-tb-15 text-center fs14 text-e54f" @click="dialogTableVisible=false">
					取消
				</div>
			</div>
		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import dynamic from '@/components/dynamic/dynamic';
	import bookList from '@/components/book-list/book-list';
	import top from '@/components/top';
	import bottom from '@/components/bottom';
	import loadMore from '@/components/load-more/load-more.vue';
	export default {
		components: {
			top,
			bottom,
			dynamic,
			bookList,
			loadMore
		},
		name: 'Home',
		data() {
			return {
				keyword:'', //搜索关键词
				status:'more',//热榜是否有更多
				showRotate:false, //是否旋转
				showRotate2:false, //是否旋转
				hotLists:[],//热榜
				focusLists:[], //关注列表
				isInit:false,
				page:1,
				totalSize:0,//总页数
				loading:false,
				iscompleted:false, //当设置为 true 时将不会再触发 infinite-scroll 事件.当所有数据全部加载完毕后，建议设置为 true
				scrollTop: 0,
				dialogTableVisible: false, //新建弹框
				tabIndex: 0,
				hotSearch: [{
						name: '学生开学了'
					},
					{
						name: '三毛'
					}
				],
				tabLists: [{
						id: 1,
						name: '动态'
					},
					{
						id: 2,
						name: '文章'
					},
					{
						id: 3,
						name: '电子书'
					},
					{
						id: 4,
						name: '付费专栏'
					}
				],
				lists: []
			}
		},
		computed: {
		  // 是否登录状态
		  isLogin() {
		    return this.$store.getters.isLogin;
		  },
		  // 是否是作家
		  is_writer() {
		    return this.$store.getters.is_writer;
		  },
		  userInfo(){
 			return this.$store.getters.userInfo;
		  }
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			this.loadData();
			window['getSonData'] = ()=>{
				this.loadData();
			};
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			/* 通过code获取openid */
			codeGetOpenid(code) {
				this.ajax('post', '/v1/619714950d880',{
					code:code
				}, res => {
					if (res.code==1) {
						localStorage.setItem('USER_INFO', JSON.stringify(res.data.userinfo))
						localStorage.setItem('user_token', res.data.userinfo.user_token)
						this.$store.commit('updateUserInfo', res.data.userinfo);
						// window.location.reload();  //刷新页面
					} else if(res.code==-999 && res.data.result){
						this.$router.push({
							path: '/bind_phone',
							query:{
								openid:res.data.openid,
								unionid:res.data.result.unionid,
								nickName:res.data.result.nickname,
								avatarUrl:res.data.result.headimgurl
							}
						})
					}
				}, err => {
					console.log('微信登录code获取失败', err);
				})
			},
			// 去发布
			async toPublish(type,path){
				let auditInfo = await this.getAuditInfo();
				// 0  审核中
				// 1  审核通过
				// 2  审核拒绝
				// 3  待支付
				let info  = type==1 ? '文章': '电子书';
				switch (auditInfo.status){
					case -99:
						this.$confirm(`只有作家才可以发布${info}哦，去认证?`, '提示', {
						  confirmButtonText: '好的',
						  type: 'info'
						}).then(() => {
						  this.$router.push('/authen')
						}).catch(() => {
						         
						});
						break;
					case 0:
						this.$confirm('认证审核中，请耐心等待', '提示', {
						  confirmButtonText: '好的',
						  type: 'info'
						}).then(() => {
						  
						}).catch(() => {
						         
						});
						break;
					case 1:
						this.$router.push({
							path:path,
							query:{
								ducoment_type:2
							}
						})
						break;
					case 2:
						let reasons = auditInfo.reason || '暂无原因';
						this.$confirm('认证审核未通过，拒绝原因为：'+reasons +'，请重新提交', '提示', {
						  confirmButtonText: '好的',
						  cancelButtonText: '不了',
						  type: 'info'
						}).then(() => {
						  this.$router.push('/authen')
						}).catch(() => {
						         
						});
						break;
					case 3:
						this.$confirm('认证未支付，去支付？', '提示', {
						  confirmButtonText: '好的',
						  cancelButtonText: '不了',
						  type: 'info'
						}).then(() => {
						  this.addOrder(auditInfo.period_id,auditInfo.id)
						}).catch(() => {
						         
						});
						break;
					default:
						break;
				}
			},
			// 获取认证信息
			getAuditInfo() {
				return new Promise((resolve,reject)=>{
					this.ajax('post', '/v1/6142e36210b86', {}, res => {
						if(res.code==-201){
							this.$router.push('/login')
						}else{
							resolve(res.data)
						}
					})
				})
			},
			// 统一下单接口
			addOrder(period_id,certified_id){
				this.ajax('post', '/v1/5d784b976769e', {
					order_type:4, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
					period_id:period_id,
					certified_id:certified_id
				}, res => {
					if (res.code == 1) {
						let order_sn = res.data.order_sn;
						this.$router.push({
							path: '/payMoney',
							query:{
								order_sn:res.data.order_sn,
								order_money:res.data.money,
								return_url:JSON.stringify(window.location.href)
							}
						})
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 动态更新
			update(){
				this.showRotate2 = true;
				setTimeout(()=>{
					this.showRotate2 = false
				},400);
				this.page = 1;
				this.loadData();
				setTimeout(()=>{
					target_top.scrollIntoView();
				}, 400);
			},
			// 页数改变
			handleSizeChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
			},
			// 跳页
			handleCurrentChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
			},
			handleScroll(e) {
				this.scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
			},
			// 发布动态
			publish(){
				this.$router.push('/publishDynac')
			},
			getNews() {
				this.ajax('post', '/common/newsList', {
					cate_id: this.calatId
				}, res => {
					if (res.code == 1) {

						this.newsList = res.data
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 显示按钮的显示与隐藏
			scrollToTop() {
				let scrollToptimer = setInterval(function() {
					// console.log("定时循环回到顶部");
					var top = document.body.scrollTop || document.documentElement.scrollTop;
					var speed = top / 4;
					document.documentElement.scrollTop -= speed;
					if (top == 0) {
						clearInterval(scrollToptimer);
					}
				}, 30);
			},
			// 推荐数据加载
			loadData() {
				this.ajax('post', '/v1/6380a30247a54', {
					list_type:1,
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
                        this.lists =  res.data.data;
                        console.log(this.lists , this.userInfo)
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			// 跳转人才详情
			toDetail(item){
				this.toBlank({
					path:'/talents_detail',
					query:{
						user_id:item.id
					}
				})
			},
			// 跳转商品详情
			todetail(id){
				this.toBlank({
					path:'/books-detail',
					query:{
						id:id,
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped="scoped">
	// @import url("./home.css");
	.loading { 
	     -webkit-animation: rotation 0.4s linear; 
	} 
	@-webkit-keyframes rotation { 
	     from { 
	       -webkit-transform: rotate(0deg); 
	     } 
	     to { 
	       -webkit-transform: rotate(180deg); 
	     } 
	}
	   ::-webkit-scrollbar {
	     width: 0 !important;
	   }
	   ::-webkit-scrollbar {
	     width: 0 !important;height: 0;
	   }

	.tab_box{
		position: sticky;
		top: 80px;
	}
	.middle-box {
		padding-bottom: 80px;
	}
	.input-box{
		width: 686px;
		height: 48px;
		background: #FFFFFF;
		opacity: 1;
		border-radius: 24px;
		input{
			width: 80%;
			height: 100%;
			background: #FFFFFF;
			padding: 0 20px;
			border-radius: 24px 0 0 24px;
		}
	}
	/* 右侧信息 */
	.title-img {
		width: 28px;
		height: 28px;
	}

	.title {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}

	.change-img {
		width: 16px;
		height: 16px;
	}
	.box-content {
		position: fixed;
		width: 270px;
		right: 315px;
		bottom: 93px;
		z-index: 1510;
		background-color: #FFFFFF;
	}

	.change-title {
		font-size: 9px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 15px;
		color: #E54F42;
		opacity: 1;
	}

	.hot-box {
		margin-top: 100px;
	}

	.hot-list {
		.hot-item {
			.hot-index {
				font-size: 14px;
				font-family: OPPOSans;
				font-weight: bold;
				line-height: 16px;
				opacity: 1;
			}
			.text-theme{
				color: #FE2D46;
			}
			.hot-title {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 14px;
				color: #333333;
				opacity: 1;
			}
		}
	}

	.focus-box {
		.check-box {
			height: 15px;
			font-size: 11px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 15px;
			color: #098EF0;
			opacity: 1;
			text-align: right;
			margin-top: 20px;
		}

		.focus-list {
			.focus-item {
				.head-img {
					width: 24px;
					height: 24px;
				}

				.name {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					opacity: 1;
				}
			}

		}
	}

	.float-box {
		position: fixed;
		right: 245px;
		bottom: 138px;
		width: 72px;
		height: 246px;
		background: #FFFFFF;
		box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
		opacity: 1;
		border-radius: 43px;
    }
    .content{
		margin-top: 32px;
        margin-left:19% ;
        width: 62%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .headtit{
        width:100%;
        height:360px;
        margin-top:70px;
        text-align: center;
		background: url('../../assets/image/pins-bg.png') no-repeat;
		background-size: 100% 100%;
        .tits{
            width: 200px;
            height: 30px;
            background: rgb(34, 20, 224);
            color: #fff;
            margin:  0 auto;
            line-height: 30px;
            position: relative;
            top: -30px;
            border-radius: 30px;
        }
    }
	.bg-ff{
		background: #fff;
	}
    .bg-f8{
        background: #f6f7f9;
    }
    .bookitem{
        position: relative;
        width: 19%;
        height: 396px;
        margin-right:1% ;
        .itemimg{
            height: 200px;
            padding: 16px;
        }
        .itemtit{
            padding:0 10px ;
            font-size: 14px;
            color: #333;
            overflow: hidden;
            display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
            text-overflow: ellipsis;
            .itemname{
                position: relative;
                top: -5px;
                left: 5px;
            }
        }
        .itemprice{
			margin-top: -10px;
            padding: 0 10px;
            .price{
                color: #FD4D39;
            }
        }
        .itemmprice{
            color: #999999;
            text-decoration: line-through;
        }
        .ptbtn{
            width: 92%;
            position:absolute;
            bottom: 24px;
            margin: 0 10px;
        }
    }
            .fs-12{
                font-size: 12px;
            }
            .fs-20{
                font-size: 20px;
            }
</style>
